import React from 'react'
import '../loader/loader.css';
const Loader = () => {
  return (
    <div className="loader">
    <div className="loader-circle"></div>
  </div>
  )
}

export default Loader
